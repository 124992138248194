/*
* Author: Vasterad
* Template: Astrum
* URL: http://themeforest.net/user/Vasterad/
*/


/* =================================================================== */
/* Import Section
====================================================================== */
@import url("base.css");				/* Default Reset, Typography, Forms, etc. */
@import url("responsive.css");			/* Responsive Grid */
@import url("icons.css");				/* Font Awesome Icons */
@import url("http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700");
@import url("http://fonts.googleapis.com/css?family=Raleway:800");


/* =================================================================== */
/* Header
====================================================================== */
#header {
	height: 86px;
	z-index: 999;
	background: #fff;
	position: relative;
	width: 100%;
	box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2);
}

.ie8 #header {
	border-bottom: 1px solid #e9e9e9;
}


/* Logo
====================================*/
#logo { margin-top: 32px; }
 
#logo a img {
	float: left;
	width: auto;
	height: 24px;
}


/* Menu
====================================*/
#navigation { float: right; }

.js .selectnav { display: none; }

.menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.menu ul li { position: relative; }

.menu ul ul {
	position: absolute;
	display: none;
	top: 100%;
	left: 0;
	z-index: 100;
	width: 175px;
}

.menu > ul li { float: left; }
.menu ul li:hover > ul { display: block; }

.menu ul a {
	display: block;
	position: relative;
}

.menu ul ul ul {
	top: 0;
	left: 100%;
}

.menu ul a { text-decoration: none; }

.menu ul > li > a {
	color: #888;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	padding: 34px 18px 31px 18px;
	overflow: hidden;
	border-bottom: 3px solid transparent;
}

#current,
.menu ul li a:hover,
.menu ul > li:hover > a { 
	border-bottom: 3px solid #a0a0a0;
	background-color: #fbfbfb;
}

#current:after {
	background: #a0a0a0;
	bottom: -2.5px;
	content: '';
	left: 50%;
	display: block;
	height: 5px;
	margin: 0 0 0 -2.5px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
	width: 5px;
}

.ie8 #current:after {
	display: none;
}

.menu ul li a {
	-webkit-transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
	-moz-transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
	-o-transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
	-ms-transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
	transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
}


/* Drop-Down */
.menu ul ul {
	background-color: #fff;
	z-index: 999;
	border-radius: 0 0 3px 3px;
	border: 1px solid #e4e4e4;
	border-top: 3px solid #a0a0a0;
	margin: -3px 0 0 0;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
}

.menu > ul ul li:hover > a {
	border-bottom: 0;
	background: transparent;
}

.menu ul ul li {
	margin: 0;
	padding: 0;
	width: 100%;
}

.menu ul ul li a {
	color: #999;
	font-size: 13px;
	line-height: 18px;
	padding: 10px 16px !important;
	border: 0;
}

.menu ul ul li { border-top: 1px solid #e8e8e8; }
.menu ul ul li:first-child { border: 0; }

.menu ul ul li a:hover {
	color: #999;
	background-color: #fbfbfb;
	border: 0;
}

.menu ul ul li a {
	-webkit-transition: background-color 50ms ease-in-out;
	-moz-transition: background-color 50ms ease-in-out;
	-o-transition: background-color 50ms ease-in-out;
	-ms-transition: background-color 50ms ease-in-out;
	transition: background-color 50ms ease-in-out;
}

.menu ul ul ul {
	border: 1px solid #e0e0e0;
	border-radius: 0 3px 3px 3px;
	margin: -1px 0 0 0;
}

.sf-sub-indicator {
	color: #c6c6c6;
	margin: 0 0 0 2px;
	width: 11px;
	display: inline-block;
	font-size: 12px;
	line-height: 0;
}

.menu ul ul .sf-sub-indicator .icon-angle-down { display: none; }


/* Search Form
====================================*/
#search-form .search-text-box {
	width: 35px;
	background-color: #f2f2f2;
	background-image: url("../img/search-icon.png");
	background-position: 11px 50%;
	background-repeat: no-repeat;
	border: none;
	color: transparent;
	font-size: 13px;
	line-height: 18px;
	padding: 8px 0 9px 0;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 30px;
}

#search-form { margin: 26px 0 0 10px; }

#search-form .search-text-box:focus {
	font-size: 13px;
	background-color: #f2f2f2;
	padding: 8px 15px 9px 35px;
	width: 140px;
	border: none;
	color: #888;
	cursor: text;
}


/* =================================================================== */
/* Mobile Navigation
====================================================================== */
#mobile-navigation { display: none; }

@media only screen and (max-width: 767px) {

	#mobile-navigation { display: block; }

	#responsive,
	.search-container { display: none; }

	#header {
		z-index: 999;
		background-color: #fff;
		height: 79px;
		margin-top: 0;
		position: relative;
		width: 100%;
	}
	
	body { padding-top: 0; }

	#header #logo {
		width: 124px;
		left: 0;
		right: 0;
		position: absolute;
		margin: 0 auto;
		margin-top: 28px;
		z-index: 99;
	}

	a.menu-trigger {
		color: #a0a0a0;
		display: block;
		font-size: 28px;
		float: left;
		background: #fff;
		z-index: 100;
		position: relative;
	}

	.search-trigger {
		color: #a0a0a0;
		display: block;
		font-size: 28px;
		float: right;
		cursor: pointer;
		background: #fff;
		z-index: 100;
		position: relative;
	}
	
	#menu-search {
		display: none;
	}

	#menu-search input {
		float: left;
		box-shadow: none;
		border: 0;
		font-size: 16px;
		color: #aaa;
		width: 50%;
		padding: 27px 0 23px 0;
	}

	a.menu-trigger,
	.search-trigger { padding: 25px 30px; }

	a.menu-trigger { padding-left:0; }
	.search-trigger { padding-right:0; }

	#menu-search input:focus { color: #888; }

	#menu-search input::-webkit-input-placeholder { color: #a0a0a0; opacity: 1;}
	#menu-search input::-moz-placeholder { color: #a0a0a0; opacity: 1; }
	#menu-search input:-ms-input-placeholder { color: #a0a0a0; opacity: 1; }
	#menu-search input:focus::-webkit-input-placeholder { color: #888; opacity: 1; }
	#menu-search input:focus::-moz-placeholder { color: #888; opacity: 1; }
	#menu-search input:focus:-ms-input-placeholder { color: #888; opacity: 1; }
	
	.jPanelMenu-panel {
		-webkit-box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.28);
		box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.28);
	}

}

#jPanelMenu-menu #current { border-bottom: 1px solid #eee; }

#jPanelMenu-menu #current:after,
#jPanelMenu-menu .sf-sub-indicator { display: none; }

#jPanelMenu-menu li a {
	display:block;
	padding: 12px 20px !important;
	background-color: #f6f6f6;
	border-bottom: 1px solid #f0f0f0;
	color: #888;
	font-weight: 500;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	font-weight: 500;
	outline: none;
}

#jPanelMenu-menu li a:hover { background-color: #f8f8f8; }

#jPanelMenu-menu li li a {
	background-color: #fff;
	padding-left: 30px !important;
}



/* =================================================================== */
/* Miscellaneous
====================================================================== */
.headline {
	font-size: 20px;
	background-color: #fff;
	padding-right: 10px;
	z-index: 5;
	position: relative;
	float: left;
}

.line {
	border-bottom: 1px solid #f0f0f0;
	width: 100%;
	float: left;
	height: 1px;
	display: inline-block;
	margin: -16px 0 25px 0;
}

.widget .line { margin-bottom: 22px; }


/* Titlebar
====================================*/
#titlebar {
	background-color: #f9f9f9;
	position: relative;
	min-height: 80px;
	margin: 0 0 20px 0;
	padding-top: 20px;
	top: -20px;
}

#titlebar h2 {
	font-size: 20px;
	font-weight: 500;
	float: left;
	width: 100%;
	margin: 25px 0 0 0;
}

#titlebar span {
	font-weight: 300;
	color: #aaa;
}


/* Tagline
====================================*/
#tagline {
	margin: 60px 0 40px 0;
	font-size: 20px;
	text-align: center;
	font-weight: 500;
	color: #505050;
}

#tagline p {
	line-height: 28px;
	margin: 3px 0;
}

#tagline span,
#tagline a {
	background: #a0a0a0;
	color: #fff;
	padding: 0 7px 1px 7px;;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
}


/* Breadcrumbs
====================================*/
#breadcrumbs ul {
	float: right;
	font-size: 13px;
	margin-top: 27px;
	font-weight: 500;
}

#breadcrumbs ul li {
	display: inline-block;
	color: #999;
	margin: 0 0 0 5px;
}

#breadcrumbs ul li:after {
	content: "";
	font-family: "FontAwesome";
	font-size: 12px;
	color: #ccc;
	margin: 0 0 0 9px;
}

#breadcrumbs ul li:last-child:after,
#breadcrumbs ul li:first-child:after {
	content: "";
	margin: 0;
}

#breadcrumbs ul li:last-child a,
#breadcrumbs ul li a { color: #888; font-weight: 600; }
#breadcrumbs ul li a:hover { color: #a0a0a0; }

#breadcrumbs ul li:first-child {
	padding-right: 0;
	margin-left: 0;
}

#breadcrumbs ul li:first-child,
#breadcrumbs ul li:last-child {
	color: #999;
	background: none;
}

#breadcrumbs ul li:last-child {
	padding:0;
	background: none;
}


/* Social Icons & Footer
====================================*/
.social-icons-footer {
	margin: -3px 0 0 0;
	float: right;
}

.social-icons-footer li {
	display: inline;
	list-style: none;
	float: left;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.social-icons-footer li i {
	display: block;
	padding-top: 5px;
}


/* Magnific Popup CSS
====================================*/
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	overflow: hidden;
	position: fixed;
	background: #000;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.mfp-wrap {
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1001;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	height: 100%;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 18px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 505;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
 }

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #aaa;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 504;
}

.mfp-preloader a {
	color: #cccccc;
}

.mfp-preloader a:hover {
	color: #fff;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}
  
.mfp-s-loading .mfp-arrow { 
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	padding: 0;
	z-index: 506;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	opacity: 1;
	padding: 0 0 18px 10px;
	text-indent: 99999;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: -13px;
	top: 20px;
	padding-right: 6px;
	width: 100%;
	background-image: url(../img/mfp-close.png);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	text-indent: 9999;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #aaa;
	font-size: 13px;
	line-height: 18px;
}

.mfp-arrow {
	position: absolute;
	top: 0;
	opacity: 0;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: solid transparent;
}

.mfp-arrow.mfp-arrow-left {
	background-image: url(../img/mfp-left.png);
	background-repeat: no-repeat;
	background-position: 50%;
}

.mfp-arrow.mfp-arrow-right {
	background-image: url(../img/mfp-right.png);
	background-repeat: no-repeat;
	background-position: 50%;
	right: 0;
}

.mfp-content:hover .mfp-arrow {
	opacity: 1;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: black;
}

.mfp-iframe-holder .mfp-close {
	top: -40px;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.mfp-figure {
	line-height: 0;
}

.mfp-bottom-bar {
	margin-top: -30px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-break: break-word;
	padding-right: 36px;
}

.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}


/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {

  .mfp-img-mobile .mfp-image-holder {
	padding-left: 0;
	padding-right: 0;
   }

  .mfp-img-mobile img.mfp-img {
	padding: 0
   }

  /* The shadow behind the image */
  .mfp-img-mobile .mfp-figure:after {
	top: 0;
	bottom: 0;
   }

  .mfp-img-mobile .mfp-bottom-bar {
	background: rgba(0, 0, 0, 0.6);
	bottom: 0;
	margin: 0;
	top: auto;
	padding: 3px 5px;
	position: fixed;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
   }

  .mfp-img-mobile .mfp-bottom-bar:empty {
	padding: 0;
   }

  .mfp-img-mobile .mfp-counter {
	right: 5px;
	top: 3px;
   }

  .mfp-img-mobile .mfp-close {
	top: 0;
	right: 0;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	text-align: center;
	padding: 0;
   }

  .mfp-img-mobile .mfp-figure small {
	display: inline;
	margin-left: 5px;
   }
}
	
@media all and (max-width: 800px) { .mfp-arrow {width: 70px;} }

.mfp-ie7 .mfp-img {
	padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
	padding: 0;
}

.mfp-ie7 .mfp-content {
	padding-top: 44px;
}

.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0;
}

.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.85;
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}


/* 404 Error Page
====================================*/
#not-found { margin: 90px 0 115px 0; }

#not-found h2 {
	text-align: center;
	font-size: 240px;
	line-height: 210px;
	font-weight: 500;
	letter-spacing: -5px;
}

#not-found p {
	text-align: center;
	font-size: 32px;
	line-height: 48px;
	color: #999;
}

#not-found i {
	color: #a0a0a0;
	font-size: 210px;
	top: -12px;
	position: relative;
}


/* Our Team
====================================*/
.team-name {
	padding: 12px 0 16px 0;
	margin: 0 0 13px 0;
	border-bottom: 1px solid #f0f0f0;
}

.team-name h5 {
	line-height: 28px;
	font-weight: 600;
}

.team-name span {
	display: block;
	font-weight: 300;
	color: #999;
}

.team-about {
	border-bottom: 1px solid #f0f0f0;
	padding: 0 0 1px 0;
	margin: 0 0 15px 0;
}
.team-about p {
	line-height: 24px;
}

.team-img {
	border-radius: 3px;
}

ol.social-icons { margin: 0 !important; }
ol.social-icons li { margin: 0 4px 0 0 !important; }


/* Google Maps
====================================*/
#googlemaps {
	padding-bottom:30%;
	border-radius: 3px;
}

.google-map-container { margin: 0 0 35px 0; }
.google-map-container img { max-width: none !important; }


/* Contact Details
====================================*/
.contact-informations {
	margin: 20px 0 0 0;
}
.contact-informations p {
	margin:0;
}

.contact-details-alt p {
	margin: -2px 0 0 22px;
	line-height: 18px;
}

.contact-informations li i {
	float: left;
	margin: 3px 5px 0 0;
	color: #909090;
}

.contact-informations li,
.contact-informations.second li {
	margin: 3px 0;
}


/* Flickr Widget
====================================*/
.flickr-widget-blog { margin: 5px -15px 0 0; }
.flickr-widget-blog img {display: block; width: 100%;}

.flickr-widget-blog a {
	float: left;
	width: 76px;
	height: 76px;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 5px solid #f2f2f2;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	border-radius: 3px;
}

.flickr-widget-blog a:hover {
	border: 5px solid #a0a0a0;
	border-radius: 3px;
}


/* Twitter Widget
====================================*/
#twitter-blog { margin: 5px 0 0 0; }

#twitter-blog li {
	margin: 0 0 15px 0;
	padding: 0 0 0 30px;
	float: left;
	line-height: 24px;
}

#twitter-blog li:before {
	content:"\54";
	font-family: "fontello";
	font-size: 13px;
	float: left;
	margin: 0 0 0 -28px;
	color: #a6a6a6;
}

#twitter-blog b a,
#twitter-blog b a:hover {
	margin-top: 5px;
	display: block;
	color: #999;
	font-weight: normal;
}


/* =================================================================== */
/* Revolution Slider
====================================================================== */
.fullwidthbanner-container {
	width: 100% !important;
	position: relative;
	padding: 0;
	max-height: 470px !important;
	overflow: hidden;
	background-image:url(../img/loader.gif);
	background-repeat: no-repeat;
	background-position: 50%;
	top: -20px;
}


/* Captions
====================================*/
.caption h2 {
	font-size: 44px;
	font-weight: 800;
	color: #444;
	text-transform: uppercase;
	font-family: "Raleway";
	letter-spacing: -0.2px;
}

.caption h3 {
	font-size: 28px;
	font-weight: 300;
	color: #a0a0a0;
	text-transform: uppercase;
	font-family: "Open Sans";
}

.caption p {
	font-size: 16px;
	font-weight: 500;
	color: #888;
	line-height: 25px;
	font-family: "Open Sans"
}

.caption.light h2 {
	color: #fff;
}

.caption.light h3 {
	color: #aaa;
}

.caption.light p {
	color: #999;
}


/* Navigation
====================================*/
.tparrows {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-moz-opacity: 1;
	-khtml-opacity: 1;
	opacity: 1;
	-webkit-transition: background-color 150ms ease-in-out, opacity 0.2s ease-out;
	-moz-transition: background-color 150ms ease-in-out, opacity 0.2s ease-out;
	-o-transition: background-color 150ms ease-in-out, opacity 0.2s ease-out;
	-ms-transition: background-color 150ms ease-in-out, opacity 0.2s ease-out;
	transition: background-color 150ms ease-in-out, opacity 0.2s ease-out;
}

.tparrows.hidearrows {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
}

.tp-leftarrow.default,
.tp-rightarrow.default {
	z-index: 100;
	cursor: pointer;
	position: relative;
	background-color: #a0a0a0;
	width: 49px;
	height: 71px;
}

.tp-leftarrow:hover,
.tp-rightarrow:hover { background-color: #a0a0a0; }

.tp-leftarrow.default {
	background-image: url(../img/slider-left-arrow.png);
	background-repeat: no-repeat;
	background-position: 45% 50%;
	border-radius: 0 3px 3px 0;
	left: 0 !important;
}

.tp-rightarrow.default {
	background-image: url(../img/slider-right-arrow.png);
	background-repeat: no-repeat;
	background-position: 55% 50%;
	border-radius: 3px 0 0 3px;
	right: 0 !important;
}


/* Other Styles
====================================*/
.tp_inner_padding	{
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	max-height:none !important;
}


.tp-simpleresponsive .slotholder *,
.tp-simpleresponsive img {
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
}

.tp-simpleresponsive .caption,
.tp-simpleresponsive .tp-caption {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
	position:absolute;
	visibility: hidden;
}

.tp-simpleresponsive img {
	max-width: none;
}

.noFilterClass {
	filter:none !important;
}


/* Shadows
====================================*/
.tp-bannershadow  {
	position:absolute;
	margin-left:auto;
	margin-right:auto;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

.tp-bannershadow.tp-shadow1 {background:url(../assets/shadow1.png) no-repeat; background-size:100%; width:890px; height:30px; bottom:-30px;}
.tp-bannershadow.tp-shadow2 {background:url(../assets/shadow2.png) no-repeat; background-size:100%; width:890px; height:60px;bottom:-60px;}
.tp-bannershadow.tp-shadow3 {background:url(../assets/shadow3.png) no-repeat; background-size:100%; width:890px; height:60px;bottom:-60px;}


/* Fullscreen Video
====================================*/
.caption.fullscreenvideo {	left:0px; top:0px; position:absolute;width:100%;height:100%;}
.caption.fullscreenvideo iframe	{ width:100% !important; height:100% !important;}

.tp-caption.fullscreenvideo {	left:0px; top:0px; position:absolute;width:100%;height:100%;}
.tp-caption.fullscreenvideo iframe	{ width:100% !important; height:100% !important;}


/* Basic Settings For Banner
====================================*/
 .tp-simpleresponsive img {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

.tp-simpleresponsive a {text-decoration:none;}

.tp-simpleresponsive ul {
	list-style:none;
	padding:0;
	margin:0;
}

.tp-simpleresponsive >ul >li{
	list-stye:none;
	position:absolute;
	visibility:hidden;
}

.tp-transparentimg {content:"url(../assets/transparent.png)"}
.tp-3d {-webkit-transform-style: preserve-3d;-webkit-transform-origin: 50% 50%;}



/*==================================================================== */
/* FlexSlider
====================================================================== */

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

/* FlexSlider Necessary Styles */
.flexslider {margin: 0; padding: 0; z-index: 1; position: relative;}
.flexslider .slides > li {display: none;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li:first-child {display: block;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .slides {display: block;}
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}

/* FlexSlider Default Theme */
.flexslider {margin: 0; border: none;position: relative; zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides {zoom: 1;}

/* Direction Nav */
.flex-direction-nav {*height: 0;}

.flex-direction-nav .flex-next {
	background-image: url(../img/slider-right-arrow.png);
	background-repeat: no-repeat;
	background-position: 55% 50%;
	border-radius: 3px 0 0 3px;
	right: 0;
}

.flex-direction-nav .flex-prev {
	background-image: url(../img/slider-left-arrow.png);
	background-repeat: no-repeat;
	background-position: 45% 50%;
	border-radius: 0 3px 3px 0;
	left: 0;
}

.flex-direction-nav a {
	width: 49px;
	height: 71px;
	margin-top: -36px;
	display: block;
	position: absolute;
	z-index: 120;
	cursor: pointer;
	text-indent: -9999px;
	top: 50%;
	background-color: #000;
	background-color: rgba(0,0,0,0.5);
	-webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
	-moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
	-o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
	-ms-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.flexslider .flex-next:hover,
.flexslider .flex-prev:hover { background-color: #a0a0a0;}

/* IE8 Styles */
.ie8 .flex-direction-nav a { filter:alpha(opacity=50); }
.ie8 .flex-direction-nav a:hover { filter:alpha(opacity=100) }

/* Caption */
.slide-caption {
	margin: 0;
	position: absolute;
	display: block;
	z-index: 120;
	right: 15px;
	bottom: 15px;
	color: #fff;
	background-color: #000;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 3px;
	padding: 5px 9px;
	font-size: 12px;
	font-weight: 300;
	line-height: 18px;
}

.slide-caption a {
	color: #fff;
}


/* =================================================================== */
/* Featured Boxes
====================================================================== */
.featured-boxes {
	float: left;
	margin: 40px 0 5px 0;
}

.featured-box {
	float: left;
	margin: 0 0 30px 0;
}

.circle .icon-magic,
.circle-3 .icon-magic { margin-top: 21px; }

.circle i,
.circle-3 i {
	font-size: 18px;
	margin: 20px 0;
	left: 37%;
	float: left;
	color: #a0a0a0;
	z-index: 5;
	position: relative;
}

.circle,
.circle-3 {
	width: 60px;
	height: 60px;
	display: block;
	border-radius: 50%;
	background-color: #f2f2f2;
	text-align: center;
	float: left;
}

.featured-desc {
	float: left;
	margin: -65px 0 0 82px;
}

.featured-desc h3 {
	font-size: 20px;
	margin-bottom: 4px;
}


.featured-box:hover > .circle {
	background-color: #a0a0a0;
}

.featured-box:hover > .circle i {
	color: #fff;
}

.circle,
.circle i,
.circle span {
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.circle span {
	z-index: 4;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #f2f2f2;
}

.featured-box:hover > .circle span {
	background-color: #a0a0a0;
	opacity: 0;
	-moz-transform: scale(1.5);
	-webkit-transform: scale(1.5);
	-o-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-moz-transition-duration: 0.7s;
	-webkit-transition-duration: 0.7s;
	-ms-transition-duration: 0.7s;
	-o-transition-duration: 0.7s;
	transition-duration: 0.7s ;
}


/* Second Style */
.circle-2 i {
	font-size: 18px;
	margin: 0;
	top: 20px;
	left: 38%;
	float: left;
	color: #a0a0a0;
	z-index: 5;
	position: relative;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.circle-2 {
	width: 60px;
	height: 60px;
	display: block;
	border-radius: 50%;
	background-color: #f2f2f2;
	text-align: center;
	float: left;
	-webkit-transition: all 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
	-moz-transition: all 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
	-o-transition: all 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
	-ms-transition: all 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
	transition: all 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
	position: relative;
	overflow: hidden;
}

.featured-box:hover > .circle-2 {
	background-color: #a0a0a0;
	box-shadow: 0 0 0 8px rgba(115,184,25,0.3);
}

.featured-box:hover > .circle-2 i {
	-webkit-animation: toBottomFromTop 0.25s forwards;
	-moz-animation: toBottomFromTop 0.25s forwards;
	animation: toBottomFromTop 0.25s forwards;
	animation-delay: 0.03s;
	-webkit-animation-delay: 0.03s;
	color: #fff;
}

@-webkit-keyframes toBottomFromTop {
	49% {
		-webkit-transform: translateY(100%); }
	50% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toBottomFromTop {
	49% {
		-moz-transform: translateY(100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toBottomFromTop {
	49% {
		transform: translateY(100%);
	}
	50% {
		opacity: 0;
		transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}


/* Third Style */
.circle-3,
.circle-3 i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.featured-box:hover > .circle-3 {
	background-color: #a0a0a0;
	box-shadow: 0 0 0 8px rgba(115,184,25,0.3);
}

.featured-box:hover > .circle-3 i {
	color: #fff;
	transform:rotate(360deg);
	-ms-transform:rotate(360deg);
	-webkit-transform:rotate(360deg);
}


/* =================================================================== */
/* Portfolio
====================================================================== */
.item-description {
	padding: 18px 20px 24px 20px;
	text-align: center;
	background-color: #f6f6f6;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	border-radius: 0 0 3px 3px;
}


.portfolio-item img {
	border-radius: 3px 3px 0 0;
}

.item-description,
.item-description h5,
.item-description span {
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

.item-description h5 {
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
}

.item-description span {
	color: #999;
	font-size: 14px;
	font-weight: 300;
	line-height: 19px;
}

#portfolio-wrapper .item-description { padding: 19px 20px 22px 20px; }
#portfolio-wrapper .item-description h5 { line-height: 22px; }

#related-work .item-description { padding: 19px 20px 22px 20px; }
#related-work .item-description h5 { line-height: 21px; }

.portfolio-item {
	display: block;
	background-color: #fff;
}

.portfolio-item:hover > figure > a .item-description {
	background-color: #a0a0a0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.portfolio-item:hover > figure > a .item-description h5 {
	color: #fff;
}

.portfolio-item:hover > figure > a .item-description span {
	color: #fff;
	opacity: 0.7;
	filter: alpha(opacity=70);
}


/* Filters
====================================*/
#portfolio-wrapper .portfolio-item {
	margin-bottom: 20px;
}

#portfolio-wrapper {
	margin-bottom: -20px;
	min-height: 500px;
}

#filters {
	margin: 0 0 -6px 0;
	float: left;
}

#filters li {
	float:left;
	display: inline-block;
	line-height: 24px;
	background: #fff;
	overflow: hidden;
}

#filters ul li {
	display: inline;
}

#filters a {
	color: #999;
	font-size: 14px;
	font-weight: 500;
	display: block;
	padding: 5px 10px;
	margin: 0 0 0 5px;
	border-radius: 3px;
	background-color: #f2f2f2;
	line-height: 20px;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.04);
	-webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
	-moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
	-o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
	-ms-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
	transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

#filters a:hover,
.selected {
	background-color: #a0a0a0 !important;
	color: #fff !important;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.showing {
	font-size: 14px;
	color: #999;
	line-height: 20px;
	margin: 5px 0 0 0;
	padding-right: 3px;
	display: inline;
	float: left;
	z-index: 9;
	position: relative;
	background: #fff;
}

.line.filters {
	margin: -11px 0 45px 0;
}

.line.showing {
	display: none;
}


/* Isotope Filtering
====================================*/

.isotope-item {
	z-index: 2;
}

.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1;
}

/* Isotope CSS3 transitions */
.isotope,
.isotope .isotope-item {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}

.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: top, left, opacity;
	transition-property: transform, opacity;
}

/* Disabling Isotope CSS3 transitions */
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}

/* Disable CSS transitions for containers with infinite scrolling */
.isotope.infinite-scrolling {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}



/* =================================================================== */
/* ShowBiz Carousel
====================================================================== */
.showbiz { position:relative; }


/* Navigation #1
====================================*/
.showbiz-navigation	{
	width:53px;
	height:25px;
	float: right;
	margin: -27px 0 0 -2px;
	background: #fff;
	padding-left: 10px;
}

.sb-navigation-left,
.sb-navigation-right,
.sb-navigation-left.notclickable,
.sb-navigation-right.notclickable {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.sb-navigation-left,
.sb-navigation-right {
	width:25px;
	height:25px;
	cursor:pointer;
	float:left;
	border-radius: 2px;
	background-color: #c4c4c4;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.sb-navigation-left	{ margin-right: 3px; }

.sb-navigation-left i,
.sb-navigation-right i {
	line-height:24px;
	font-size:17px;
	color:#fff;
	margin-left: 10px;
}

.sb-navigation-left:hover,
.sb-navigation-right:hover {
	background-color: #a0a0a0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.sb-navigation-left.notclickable,
.sb-navigation-right.notclickable {
	cursor:default;
	background-color: #f2f2f2;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.06);
}

.sb-navigation-left.notclickable i,
.sb-navigation-right.notclickable i {
	color: #c0c0c0;
}


/* Navigation #2
====================================*/
.sb-navigation-left-2,
.sb-navigation-right-2,
.sb-navigation-left-2.notclickable,
.sb-navigation-right-2.notclickable {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.sb-navigation-left-2,
.sb-navigation-right-2 {
	cursor:pointer;
	margin-top: 42px;
}

.sb-navigation-left-2	{float:left;}
.sb-navigation-right-2	{float:right;}

.sb-navigation-left-2 i,
.sb-navigation-right-2 i {
	line-height:24px;
	font-size:24px;
	color:#c4c4c4;
}

.sb-navigation-left-2:hover,
.sb-navigation-right-2:hover {

}

.sb-navigation-left-2.notclickable,
.sb-navigation-right-2.notclickable {
	cursor:default;

}

.sb-navigation-left-2.notclickable i,
.sb-navigation-right-2.notclickable i {
	color: #c4c4c4;
}


/* Basic Styles
====================================*/
.showbiz ul,
.showbiz li {
	list-style: none;
	margin:0;
	padding:0;
}

.showbiz ul li {
	position: relative;
	-webkit-transform: translateZ(10);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	margin: 20px 20px 20px 0;

}

.showbiz.our-clients ul li {
	margin: 15px 40px 0 0;
}

.showbiz.our-clients {
	margin-bottom: -10px;
}

.showbiz .overflowholder {
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index:2;
}

.showbiz .overflowholder ul {
	width:100000px;
	position: absolute;
	top: 0px;
	left: 0px;
}

.showbiz .overflowholder ul li {
	float: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position:relative;
}

.mediaholder {
	position:relative;
	overflow:hidden;
	width:100%;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box
}

.mediaholder img {
	width:100%;
	position:relative;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}


/* Hover / Animations
====================================*/
.hovericon {
	position:absolute;
	left:50%;
	top: 65%;
	color:#fff;
	font-size: 18px;
	margin-top: -30px;
	margin-left: -30px;
	width: 60px;
	height: 60px;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F000000,endColorstr=#7F000000)";
	background-color: rgba(0,0,0,0.5);
	border-radius: 50%;
}


.ie8 .hovercover {
	display: none;
}

.hovercover {
	width:100%;
	height:100%;
	position:absolute;
	top:0px;
	left:0px;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#59000000,endColorstr=#59000000)";
	background-color: rgba(0,0,0,0.35);
	opacity:0;
	border-radius: 3px;
}

.portfolio-item .hovercover {
	border-radius: 3px 3px 0 0;
}

.hoverzoom {
	background: url(../img/hoverzoom.png) no-repeat;
	display: block;
	height: 18px;
	width: 18px;
	margin: 21px 0 0 21px;
}

.hoverlink {
	background: url(../img/hoverlink.png) no-repeat;
	display: block;
	height: 19px;
	width: 19px;
	margin: 21px 0 0 20px;
}


/* =================================================================== */
/* Blog
====================================================================== */
.post {
	padding: 0 0 40px 0;
	margin: 0 0 40px 0;
	border-bottom: 1px solid #f0f0f0;
}

.post-img img { border-radius: 3px; }
.post-img  { margin: 0 0 30px 0; }
.medium .post-img { margin:0; }


/* Post Content */
.post-content,
.post-format {
	float: left;
}

.post-format .icon-camera {
	font-size: 14px;
	margin-top: 23px;
	left: 38%
}

.post-content { margin: -57px 0 0 89px }
.medium .post-content {margin:0;}
.post-content h2 a { color: #505050; }
.post-content h2 a:hover { color: #707070; }
.post-content p { margin-bottom: 19px; }


/* Meta */
.meta { margin: 0 0 12px 0; }

.meta ul {
	line-height: 22px;
	margin: 1px 0 0 0;
}

.meta ul li {
	display: inline-block;
	font-weight: 300;
	color: #999;
	margin: 0 5px 0 0;
	padding: 0 10px 0 0;
	background: url('../img/blog-divider.png') no-repeat 100% 55%;
}

.meta ul li:last-child { background: 0; }
.meta ul li a { color: #999; }

.meta ul li a,
.post-content h2 a:hover {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.meta ul li a:hover { color: #a0a0a0; }


/* Comments
====================================*/
.comments-sec {
	float: left;
	width: 100%;
	margin: -5px 0 25px 0;
}

ol.commentlist {
	float: left;
	width: 100%;
	margin: 0;
}

ol.commentlist li {
	float: left;
	padding: 10px 0 15px 0;
}

ol.commentlist li:first-child {
	border: 0;
	padding: 25px 0 0px 0;
}

ol.commentlist li {list-style: none;}

ol li ol.childlist{
	float: right;
	width: 86%;
	margin: 0;
}

ol.commentlist li ol.childlist li:first-child {
	padding: 25px 0 0 0;
}

ol.commentlist li ol.childlist li {
	margin: 0px 0 15px 0;
}

.comments-amount {
	font-weight: 300;
	color: #aaa;
}

ol li ol.childlist .comment-des {
	float: left;
	width: 82.6%;
}

.avatar {
	float: left;
}

.avatar img {
	float: left;
	max-width: 70px;
	border-radius: 3px;
}

.comment-des p {
	margin: 0;
	line-height: 23px;
}

.comment-des {
	float: left;
	margin: -70px 0 0 90px;
	width: 85%;
	background: #f8f8f8;
	color: #888;
	border-radius: 3px;
	-webkit-box-shadow:: inset 0px -1px 0px 0px rgba(0,0,0, 0.06);
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.06);
	padding: 19px 22px 22px 22px;
}

.arrow-comment {
	width: 10px;
	height: 20px;
	position: relative;
	float: left;
	background: url(../img/comment-arrow.png) no-repeat;
	margin-left: -32px;
}

.comment-des strong {
	float: left;
	padding-right: 5px;
	font-size: 14px;
	color: #666;
}

.comment-des span {
	float: left;
	color: #999;
}

.comment-by {
	float: left;
	width: 100%;
	padding-bottom: 8px;
	padding-top: 5px;
}

.comment-by span.reply {
	float: right;
	display: inline;
}

.comment-by span.reply a {
	float: right;
	height: 17px;
	margin-left: 5px;
	font-weight: normal;
	color: #999;
}

.comment-by span.reply a i {
	color: #bbb;
	transition: all 0.1s ease-in-out;
}

.comment-by span.reply a:hover,
.comment-by span.reply a:hover i {
	color: #a0a0a0;
}

.comment-by span.date {
	color: #999;
	float: left;
	font-weight: 300;
	background: url('../img/blog-divider.png') no-repeat 0 55%;
	padding: 0 0 0 9px;
	margin: 0 0 0 2px;
}


/* Pagination
====================================*/
.pagination {
	margin: 0;
}

.pagination ul li a {
	padding: 8px 11px;
	border-bottom: none;
	display: inline-block;
	color: #999;
	float: left;
	margin-right: 4px;
	background-color: #f2f2f2;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	box-shadow:  inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	border-radius: 3px;
}

.pagination .current,
.pagination ul li a:hover {
	background: #a0a0a0;
	color: #fff;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.15);
}


/* Tags
====================================*/
.tags {
	margin: 10px 0 0 0;
}

.tags a {
	padding: 4px 11px 4px 11px;
	display: inline-block;
	color: #999;
	background-color: #f2f2f2;
	margin: 0 2px 6px 0;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	border-radius: 3px;
}

.tags a:last-child {
	margin-right: 0;
}

.tags a:hover {
	background: #a0a0a0;
	color: #fff;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}


/* Widget Tabs
====================================*/
.widget-tabs li {
	padding: 0 0 12px 0;
	margin: 12px 0 0 0;
	border-bottom: 1px solid #f0f0f0;
}

.widget-tabs li:first-child {
	margin: 4px 0 0 0;
}

.widget-tabs li:last-child {
	border-bottom: 0;
}

.widget-thumb a img {
	display: block;
	width: 70px;
	height: 70px;
	border: 5px solid #f2f2f2;
	border-radius: 3px;
	float: left;
	margin: 0 10px 0 0;
}

.widget-text h4 {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	padding: 3px 0 0 0;
}

.widget-text h4 a {
	color: #777;
}

.widget-text h4 a:hover {
	color: #999;
}

.widget-text span {
	color: #a0a0a0;
	font-weight: 300;
	margin: 4px 0 0 0;
	display: block;
}

.widget-tabs.comments .widget-text span {
	padding: 5px 0 0 0;
	margin: 0;
}


/* Widget
====================================*/
.widget {
	margin: 30px 0 0 0;
}

.widget p {
	margin: 0;
}

.categories a {
	color: #888;
	display: block;
	line-height: 19px;
	padding: 3px 0;
}

.categories a:hover {
	color: #a0a0a0;
}

.categories ul li:before {
	content: "";
	font-family: "FontAwesome";
	font-size: 13px;
	color: #ccc;
	margin: 3px 9px 0 0;
	float: left;
}

/* Blog Tabs */
.tabs-nav.blog li {
	width: 33.334%;
}

.tabs-nav.blog li a i {
	margin: 0 auto;
	width: 100%;
	font-size: 18px;
	color: #999;
}

.tabs-nav li.active a i {
	color: #fff;
}

.tabs-nav.blog li a i.icon-star {
	font-size: 17px;
}

.tabs-nav.blog li a i.icon-time,
.tabs-nav.blog li a i.icon-star,
.tabs-nav.blog li a i.icon-comments-alt {
	position: relative;
	top: 1px;
}


/* =================================================================== */
/* Forms
====================================================================== */

/* Contact Form
====================================*/
#contact fieldset div  {
	margin-bottom: 20px;
}

#contact fieldset div input {
	width: 240px;
}

#contact textarea {
	width: auto;
	min-width: 97.5%;
	min-height: 180px;
	background: #fff;
	resize: vertical;
}

#contact input,
#contact textarea,
#contact select {
	-webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
	-moz-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
	-o-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
	-ms-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
	transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
}

#contact input:focus
#contact textarea:focus,
#contact select:focus {
	border: 1px solid #d6d6d6;
	outline: none;
	position: relative;
	z-index: 5;
}

#contact input.error,
#contact textarea.error,
#contact select.error {
	border: 1px solid #f2c4c2;
	background: #ffeceb;
}

#contact label span {
	color: #e24747;
}

#contact input[type="submit"][disabled] {
	background: #a0a0a0;
}

#message {
	margin: 0;
	padding: 0;
	display: block;
	background: transparent none;
}

.loader {
	padding: 10px;
	float: left;
}


/* Add Comment
====================================*/
.add-comment label {
	margin: 0 0 4px 0;
}

.add-comment label span {
	color: #e24747;
}

.add-comment input {
	margin: 0 0 20px 0;
	min-width: 240px;
}

.add-comment textarea {
	margin: 0 0 18px 0;
	width: auto;
	min-width: 97.5%;
	min-height: 180px;
	background: #fff;
	resize: vertical;
}

input.add-comment-btn {
	float: left;
	min-width: auto;
	margin: 0;
}



/* =================================================================== */
/* Shortcodes
====================================================================== */

/* Buttons
====================================*/
.button,
input[type="button"],
input[type="submit"] {
	padding: 7px 14px;
	display: inline-block;
	border: 0;
	font-weight: 500;
	outline: none;
	font-size: 14px;
	cursor: pointer;
	width: auto;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius: 3px;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.button i {
	margin: 0 2px 0 0;
}

.button.gray {
	color: #fff;
	background: #aaa;
}

.button.gray:hover {
	background: #a0a0a0;
}

.button.light {
	color:#999;
	background:#f2f2f2;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
}

.button.light:hover {
	color: #fff;
	background: #a0a0a0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.button.color {
	color:#fff; background: #a0a0a0;
}

.button.color:hover {
	background: #a0a0a0;
}

/* Input Button */
input[type="button"],
input[type="submit"],
input[type="button"]:focus,
input[type="submit"]:focus {
	color:#fff;
	background: #a0a0a0;
	padding: 8px 14px;
	border: none !important;
}

input[type="button"],
input[type="submit"] {
	float: left;
}

input[type="button"]:hover,
input[type="submit"]:hover {
	background: #a0a0a0;
}


/* Icons Demo
====================================*/
.the-icons{
	list-style-type:none;
	margin:0
}

.the-icons li [class^="icon-"],
.the-icons li [class*=" icon-"]{
	display:inline-block;
	text-align:center
}

.the-icons li {
	display:block;
	line-height:32px;
	height:30px;
	padding: 0 0 0 2px;
	border-radius:3px
}

.the-icons li,
.the-icons li:hover{
	color: #666;
}

.the-icons li i{
	color: #444;
}

.the-icons li [class^="icon-"],
.the-icons li [class*=" icon-"]{
	width:32px;
	font-size:14px
}

.the-icons li:hover{
	background-color:#f4f4f4;
	text-decoration:none;
}

.the-icons l:hover [class^="icon-"],
.the-icons li:hover [class*=" icon-"]{
	*font-size:28px;
	*vertical-align:middle
}

.the-icons li:hover [class^="icon-"]:before,
.the-icons li:hover [class*=" icon-"]:before{
	font-size:24px;
	vertical-align:-3px
}


/* Social Icons
====================================*/
.social-icons li {
	display: inline;
	float: left;
	margin-right: 4px;
}

.social-icons li a {
	width: 42px;
	height: 42px;
	display: inline-block;
	background-color: #f2f2f2;
	border-radius: 3px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: relative;
	overflow: hidden;
}


/* Common Styles */
.social-icons li a i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: absolute;
	z-index: 20;
}

.social-icons li a:hover,
.social-icons li a i {
	color: #fff;
}

.social-icons li a i {
	top: -30px;
	opacity:0;
}

.social-icons li a:hover i {
	top: 0;
	opacity: 1;
}

.twitter:before,
.wordpress:before,
.facebook:before,
.linkedin:before,
.steam:before,
.tumblr:before,
.github:before,
.delicious:before,
.instagram:before,
.xing:before,
.amazon:before,
.dropbox:before,
.paypal:before,
.lastfm:before,
.gplus:before,
.yahoo:before,
.pinterest:before,
.dribbble:before,
.flickr:before,
.reddit:before,
.vimeo:before,
.spotify:before,
.rss:before,
.youtube:before,
.blogger:before,
.appstore:before,
.digg:before,
.evernote:before,
.fivehundredpx:before,
.forrst:before,
.stumbleupon:before,
.dribbble:before {
	font-family: "fontello";
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a0;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	position: absolute;
	z-index: 20;
}


/* Icon Styles */
.twitter:before {font-size: 14px;}
.twitter i, .twitter:before { margin: 13px 0 0 12px; }
.twitter:before { content: "\54"; top: 0; opacity:1; }
.twitter:hover:before { top: 30px; opacity: 0; }
.twitter:hover { background-color: #3bc1ed; }

.dribbble i, .dribbble:before { margin: 13px 0 0 13px; }
.dribbble:before { content: "\44"; top: 0; opacity:1; }
.dribbble:hover:before { top: 30px; opacity: 0; }
.dribbble:hover { background-color: #ef5a92; }

.facebook i, .facebook:before { margin: 13px 0 0 16px; }
.facebook:before { content: "\66"; top: 0; opacity:1; }
.facebook:hover:before { top: 30px; opacity: 0; }
.facebook:hover { background-color: #4a6d9d; }

.linkedin i, .linkedin:before { margin: 13px 0 0 13px; }
.linkedin:before { content: "\4c"; top: 0; opacity:1; }
.linkedin:hover:before { top: 30px; opacity: 0; }
.linkedin:hover { background-color: #0b7bb5; }

.delicious i, .delicious:before { margin: 13px 0 0 13px; }
.delicious:before { content: "\23"; top: 0; opacity:1; }
.delicious:hover:before { top: 30px; opacity: 0; }
.delicious:hover { background-color: #3274d1; }

.paypal i, .paypal:before { margin: 13px 0 0 13px; }
.paypal:before { content: "\24"; top: 0; opacity:1; }
.paypal:hover:before { top: 30px; opacity: 0; }
.paypal:hover { background-color: #2e689a; }

.gplus i, .gplus:before { margin: 13px 0 0 13px; }
.gplus:before { content: "\2b"; top: 0; opacity:1; }
.gplus:hover:before { top: 30px; opacity: 0; }
.gplus:hover { background-color: #d53824; }

.stumbleupon i, .stumbleupon:before { margin: 13px 0 0 11px; }
.stumbleupon:before { content: "\2f"; top: 0; opacity:1; }
.stumbleupon:hover:before { top: 30px; opacity: 0; }
.stumbleupon:hover { background-color: #eb4924; }

.fivehundredpx i, .fivehundredpx:before { margin: 13px 0 0 11px; }
.fivehundredpx:before { content: "\30"; top: 0; opacity:1; }
.fivehundredpx:hover:before { top: 30px; opacity: 0; }
.fivehundredpx:hover { background-color: #444; }

.pinterest i, .pinterest:before { margin: 13px 0 0 15px; }
.pinterest:before { content: "\31"; top: 0; opacity:1; }
.pinterest:hover:before { top: 30px; opacity: 0; }
.pinterest:hover { background-color: #cb1f25; }

.forrst i, .forrst:before { margin: 13px 0 0 15px; }
.forrst:before { content: "\3a"; top: 0; opacity:1; }
.forrst:hover:before { top: 30px; opacity: 0; }
.forrst:hover { background-color: #40833e; }

.digg i, .digg:before { margin: 13px 0 0 12px; }
.digg:before { content: "\3b"; top: 0; opacity:1; }
.digg:hover:before { top: 30px; opacity: 0; }
.digg:hover { background-color: #205685; }

.spotify i, .spotify:before { margin: 13px 0 0 13px; }
.spotify:before { content: "\3d"; top: 0; opacity:1; }
.spotify:hover:before { top: 30px; opacity: 0; }
.spotify:hover { background-color: #6ca405; }

.reddit i, .reddit:before { margin: 13px 0 0 12px; }
.reddit:before { content: "\3e"; top: 0; opacity:1; }
.reddit:hover:before { top: 30px; opacity: 0; }
.reddit:hover { background-color: #404040; }

.appstore i, .appstore:before { margin: 13px 0 0 14px; }
.appstore:before { content: "\41"; top: 0; opacity:1; }
.appstore:hover:before { top: 30px; opacity: 0; }
.appstore:hover { background-color: #c0c0c0; }

.blogger i, .blogger:before { margin: 13px 0 0 15px; font-size: 14px !important; }
.blogger:before { content: "\42"; top: 0; opacity:1; }
.blogger:hover:before { top: 30px; opacity: 0; }
.blogger:hover { background-color: #ff6501; }

.dribbble i, .dribbble:before { margin: 13px 0 0 13px; }
.dribbble:before { content: "\44"; top: 0; opacity:1; }
.dribbble:hover:before { top: 30px; opacity: 0; }
.dribbble:hover { background-color: #ef5a92; }

.evernote i, .evernote:before { margin: 13px 0 0 15px; }
.evernote:before { content: "\45"; top: 0; opacity:1; }
.evernote:hover:before { top: 30px; opacity: 0; }
.evernote:hover { background-color: #6bb130; }

.flickr i, .flickr:before { margin: 13px 0 0 13px; }
.flickr:before { content: "\46"; top: 0; opacity:1; }
.flickr:hover:before { top: 30px; opacity: 0; }
.flickr:hover { background-color: #136dd5; }

.rss i, .rss:before { margin: 13px 0 0 14px; font-size: 14px !important; }
.rss:before { content: "\52"; top: 0; opacity:1; }
.rss:hover:before { top: 30px; opacity: 0; }
.rss:hover { background-color: #fe8f00; }

.youtube i, .youtube:before { margin: 13px 0 0 14px; font-size: 18px !important; }
.youtube:before { content: "\55"; top: 0; opacity:1; }
.youtube:hover:before { top: 30px; opacity: 0; }
.youtube:hover { background-color: #bf2f29; }

.vimeo i, .vimeo:before { margin: 13px 0 0 13px; font-size: 14px !important; }
.vimeo:before { content: "\56"; top: 0; opacity:1; }
.vimeo:hover:before { top: 30px; opacity: 0; }
.vimeo:hover { background-color: #4c8ab0; }

.xing i, .xing:before { margin: 13px 0 0 14px; }
.xing:before { content: "\58"; top: 0; opacity:1; }
.xing:hover:before { top: 30px; opacity: 0; }
.xing:hover { background-color: #036568; }

.yahoo i, .yahoo:before { margin: 13px 0 0 11px; }
.yahoo:before { content: "\59"; top: 0; opacity:1; }
.yahoo:hover:before { top: 30px; opacity: 0; }
.yahoo:hover { background-color: #9251a2; }

.amazon i, .amazon:before { margin: 13px 0 0 12px; }
.amazon:before { content: "\61"; top: 0; opacity:1; }
.amazon:hover:before { top: 30px; opacity: 0; }
.amazon:hover { background-color: #2f3842; }

.steam i, .steam:before { margin: 13px 0 0 11px; }
.steam:before { content: "\62"; top: 0; opacity:1; }
.steam:hover:before { top: 30px; opacity: 0; }
.steam:hover { background-color: #383838; }

.dropbox i, .dropbox:before { margin: 13px 0 0 13px; }
.dropbox:before { content: "\64"; top: 0; opacity:1; }
.dropbox:hover:before { top: 30px; opacity: 0; }
.dropbox:hover { background-color: #2c92dd; }

.github i, .github:before { margin: 13px 0 0 13px; }
.github:before { content: "\67"; top: 0; opacity:1; }
.github:hover:before { top: 30px; opacity: 0; }
.github:hover { background-color: #5c7381; }

.lastfm i, .lastfm:before { margin: 13px 0 0 11px; }
.lastfm:before { content: "\6c"; top: 0; opacity:1; }
.lastfm:hover:before { top: 30px; opacity: 0; }
.lastfm:hover { background-color: #d21309; }

.tumblr i, .tumblr:before { margin: 13px 0 0 15px; }
.tumblr:before { content: "\74"; top: 0; opacity:1; }
.tumblr:hover:before { top: 30px; opacity: 0; }
.tumblr:hover { background-color: #3d658d; }

.wordpress i, .wordpress:before { margin: 13px 0 0 14px; }
.wordpress:before { content: "\77"; top: 0; opacity:1; }
.wordpress:hover:before { top: 30px; opacity: 0; }
.wordpress:hover { background-color: #383838; }

.instagram i, .instagram:before { margin: 13px 0 0 14px; }
.instagram:before { content: "\f31f"; top: 0; opacity:1; }
.instagram:hover:before { top: 30px; opacity: 0; }
.instagram:hover { background-color: #3f6f94; }


/* Tooltips
====================================*/
#tiptip_holder {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99999;
	font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#tiptip_holder.tip_top {
	padding-bottom: 5px; 
	-webkit-transform: translateX(12px)
}

#tiptip_holder.tip_bottom { padding-top: 5px; }
#tiptip_holder.tip_right { padding-left: 5px; }
#tiptip_holder.tip_left { padding-right: 5px; }

#tiptip_content {
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	padding: 6px 10px;
	background-color: #666;
	border-radius: 3px;
}

#tiptip_arrow, #tiptip_arrow_inner {
	position: absolute;
	border-color: transparent;
	border-style: solid;
	border-width: 6px;
	height: 0;
	width: 0;
}

#tiptip_holder.tip_top #tiptip_arrow { border-top-color: #666; }
#tiptip_holder.tip_bottom #tiptip_arrow { border-bottom-color: #666; }
#tiptip_holder.tip_right #tiptip_arrow { border-right-color:#666; }
#tiptip_holder.tip_left #tiptip_arrow { border-left-color: #666; }

#tiptip_holder.tip_top #tiptip_arrow_inner {
	margin-top: -7px;
	margin-left: -6px;
	border-top-color: #666;
}

#tiptip_holder.tip_bottom #tiptip_arrow_inner {
	margin-top: -5px;
	margin-left: -6px;
	border-bottom-color: #666;
}

#tiptip_holder.tip_right #tiptip_arrow_inner {
	margin-top: -6px;
	margin-left: -5px;
	border-right-color: #666;
}

#tiptip_holder.tip_left #tiptip_arrow_inner {
	margin-top: -6px;
	margin-left: -7px;
	border-left-color: #666;
}


/* Tabs
====================================*/
.tabs-nav {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

.tabs-nav li {
	float: left;
	line-height: 38px;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.tabs-nav li a {
	background-color: #f2f2f2;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
	color: #888;
	font-weight: 500;
	display: block;
	letter-spacing: 0;
	outline: none;
	padding: 0 20px;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
}

.tabs-nav li:first-child a { border-radius: 3px 0 0 3px; }
.tabs-nav li:last-child a { border-radius: 0 3px 3px 0; }

.tabs-nav li.active a {
	background: #a0a0a0;
	color: #fff;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.tab-content { padding: 10px 0px; }

.tabs-container {
	overflow: hidden;
	width: 100%;
}


/* Accordions & Toggles
====================================*/
.ui-accordion .ui-accordion-header,
.trigger a {
	display: block;
	cursor: pointer;
	position: relative;
	padding: 0;
	line-height: 26px;
	outline: none;
	color: #888;
	font-size: 14px;
	background-color: #f4f4f4;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	-webkit-transition: background-color 200ms ease-in-out;
	-moz-transition: background-color 200ms ease-in-out;
	-o-transition: background-color 200ms ease-in-out;
	-ms-transition: background-color 200ms ease-in-out;
	transition: background-color 200ms ease-in-out;
	padding: 10px 16px;
	margin-bottom: 10px;
	border-radius: 3px;
}

.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active,
.trigger.active a,
.trigger.active a:hover {
	background: #a0a0a0;
	color: #fff;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.ui-accordion .ui-accordion-content,
.toggle-container {
	padding: 0 16px 10px 16px;
}

.ui-accordion .ui-accordion-content p { margin: 0; }
.ui-accordion .ui-accordion-content { }

.ui-accordion-icon:before,
.ui-accordion-icon-active:before,
.toggle-icon:before {
	font-family:"FontAwesome";
	content:"";
	color: #a0a0a0;
	font-size: 18px;
	display: block;
	float: left;
	line-height: 25px;
	margin: 0 8px 0 0;
}

.toggle-container p { margin: 0; }

.ui-accordion-icon-active:before,
.trigger.active .toggle-icon:before {
	content:"";
	color: #fff;
}

.faq .toggle-icon:before,
.faq .trigger.active .toggle-icon:before {
	content:"";
}


/* Skill Bars
===================================== */
.skill-bar {
	height: 38px;
	position: relative;
	margin: 0 0 14px 0;
	background-color: #f2f2f2;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	border-radius: 3px;
}

.skill-title {
	color: #fff;
	font-size: 14px;
	background-color: rgba(0,0,0, 0.05);
	border-radius: 3px 0 0 3px;
	padding: 7px 14px;
	display: block;
	float: left;
	position: relative;
	z-index: 5;
}

.skill-title i {
	margin: 0 3px 0 0;
}

.skill-bar-value {
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	background: #a0a0a0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
	border-radius: 3px;
	overflow: visible !important;
}


/* Alert Boxes
====================================*/
.notification {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 15px;
	position: relative;
	padding: 13px 40px 13px 18px;
	border-radius: 3px;
	box-shadow:  0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}

.notification p {
	margin: 0;
}

.notification span {
	font-weight: 600;
}

.notification.success,
.notification.success strong {
	background-color: #EBF6E0;
	color: #5f9025;
	border: 1px solid #b3dc82;
}

.notification.error,
.notification.error strong {
	background-color: #ffe9e9;
	color: #de5959;
	border: 1px solid #fbc4c4;
}

.notification.warning,
.notification.warning strong {
	background-color: #FBFADD;
	color: #8f872e;
	border: 1px solid #ded58a;
}

.notification.notice,
.notification.notice strong {
	background-color: #E9F7FE;
	color: #5091b2;
	border: 1px solid #b6d7e8;
}

.notification strong { border: none !important; }

.notification.success .close,
.notification.error .close,
.notification.warning .close,
.notification.notice .close {
	padding: 0px 9px;
	position: absolute;
	right: 0;
	top: 22px;
	display: block;
	height: 8px;
	width: 8px;
	cursor: pointer;
}

.notification.success .close {background: url(../img/alert_boxes_close_ico.png) 0 -8px no-repeat;}
.notification.error .close {background: url(../img/alert_boxes_close_ico.png) 0 0 no-repeat;}
.notification.warning .close {background: url(../img/alert_boxes_close_ico.png) 0 -16px no-repeat;}
.notification.notice .close {background: url(../img/alert_boxes_close_ico.png) 0 -24px no-repeat;}


/* Regular Table
====================================*/
table.standard-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border:none;
	margin-bottom: 15px;
	box-shadow:  0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}

table.standard-table th {
	border: 1px solid #e4e4e4;
	border-right: none;
	background-color: #fafafa;
	text-align: left;
	padding: 10px 15px;
	color: #444;
	vertical-align: top;
	font-size: 14px;
	font-weight: 600;
}

table.standard-table th:first-child { border-radius: 3px 0 0 0; }
table.standard-table th:last-child { border-radius: 0 3px 0 0; }

table.standard-table td:last-child {
	border-right: 1px solid #e4e4e4;
}

table.standard-table th:last-child {
	border-right: 1px solid #e4e4e4;
}

table.standard-table td {
	padding: 10px 15px;
	border: #e4e4e4 1px solid;
	border-top: none;
	border-right: none;
}

table.standard-table tr:hover td { background-color: #fafafa; }
table.standard-table tr { border-radius: 3px }


/* Testimonials
====================================*/
.testimonials {
	background: #f8f8f8;
	color: #888;
	padding: 25px;
	line-height: 23px;
	border-radius: 3px;
	-webkit-box-shadow:: inset 0px -1px 0px 0px rgba(0,0,0, 0.06);
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.06);
}

.testimonials-bg {
	background: url(../img/testimonials-bg.png) no-repeat;
	margin-top: -1px;
	display: block;
	margin-left: 27px;
	width: 200px;
	height: 30px;
}

.testimonials-author {
	color: #a0a0a0;
	float: right;
	display: block;
	margin-top: -23px;
	margin-bottom: 20px;
}

.testimonials-author span {
	color: #a0a0a0;
}


/* Happy Clients
====================================*/
.sb-navigation-left-2.alt,
.sb-navigation-right-2.alt {
	top: 57%;
	margin: 0;
	position: absolute;
	height: 20px;
	width: 20px;
	z-index: 99;
}

.sb-navigation-left-2.alt { left: 10px; }
.sb-navigation-right-2.alt { right:0; }

.happy-clients-photo img {
	margin: 0 auto;
	display: block;
	margin-bottom: 11px;
	border-radius: 50%;
	width: 80px;
	height: 80px;
}

.happy-clients-cite {
	font-size: 20px;
	font-weight: 300;
	color: #999;
	width: 75%;
	margin: 0 auto;
	display: block;
	text-align: center;
	line-height: 30px;
	margin-bottom: 7px;
}

.happy-clients-author {
	font-size: 18px;
	font-weight: 500;
	color: #a0a0a0;
	margin: 0 auto;
	display: block;
	text-align: center;
	line-height: 32px;
}


/* Dropcaps
====================================*/
.dropcap {
	float: left;
	color: #a0a0a0;
	font-size: 58px;
	line-height: 54px;
	padding-top: 4px;
	padding-right: 10px;
	margin-top: -2px;
}

.dropcap.gray { color: #555; }


/* Highlights
====================================*/
.highlight.color,
.highlight.gray,
.highlight.light {
	padding: 2px 6px;
	color: #fff;
	border-radius: 3px;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.highlight.color { background: #a0a0a0; }
.highlight.gray { background: #aaa; }

.highlight.light {
	background: #f2f2f2;
	color: #888;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
}


/* Blockquote
====================================*/
blockquote {
	border-left: 3px solid #e8e8e8;
	padding-left: 20px;
	color: #a0a0a0;
	line-height: 24px;
	margin: 5px 0 20px 15px;
}


/* Large Notice
====================================*/
.large-notice {
	padding: 32px;
	border-radius: 3px;
	background-color: #fcfcfc;
	border: 1px solid #e4e4e4;
}

.large-notice h2 {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 22px;
}

.large-notice p {
	font-size: 14px;
	color: #888;
	line-height: 22px;
}


/* Notice Boxes
====================================*/
.notice-box {
	background: #f8f8f8;
	padding: 32px 28px;
	border-radius: 4px;
	margin:0 0 20px 0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.05);
	border-radius: 3px;
	text-align: center;
}

.notice-box,
.notice-box i,
.notice-box p,
.notice-box h3 {
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.notice-box p {
	margin: 0;
	color: #777;
}
.notice-box h3 {
	line-height: 26px;
	padding-bottom: 8px;
}

.notice-box i {
	font-size: 80px;
	height: 80px;
	color: #a0a0a0;
	margin: 10px 0;
	display: block;
}

.notice-box:hover {
	background: #a0a0a0;
	color: #fff;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
}

.notice-box:hover h3,
.notice-box:hover p,
.notice-box:hover i {
	color: #fff;
}


/* List Styles
====================================*/
.list-1 li,
.list-2 li,
.list-3 li,
.list-4 li {
	list-style: none;
	margin: 7px 0;
	margin-left: 2px;
}

.list-1 li:first-child,
.list-2 li:first-child,
.list-3 li:first-child,
.list-4 li:first-child {
	margin-top: 0;
}

.list-1 li:before,
.list-2 li:before,
.list-3 li:before,
.list-4 li:before {
	content: "";
	font-family: "FontAwesome";
	margin: 0 7px 0 0;
	position: relative;
	color: #a0a0a0;
}

.list-1 li:before { content: ""; }
.list-2 li:before { content: ""; }
.list-3 li:before { content: ""; }
.list-4 li:before { content: ""; }


/* Pricing Tables 
===================================== */
.plan { box-shadow:  0px 1px 1px 0px rgba(0, 0, 0, 0.03); }

.plan h3 {
	width: 100%;
	font-size: 22px;
	padding: 18px 0;
	color: #fff;
	font-weight: 300;
	text-align: center;
	background-color: #b4b4b4;
	border-radius: 3px 3px 0 0;
}

.plan-price {
	font-size: 36px;
	font-weight: 300;
	color: #fff;
	text-align: center;
	padding: 25px 0 22px 0;
	position: relative;
	background-color: #a7a7a7;
}

.plan-currency {
	font-size: 24px;
	color: #fff;
	position: absolute;
	margin: 0px -20px;
}

.period {
	display: block;
	font-size: 16px;
	margin: 5px 0 0 0;
	opacity: 0.6;
}

.plan-features {
	background: #fff;
	border: 1px solid #e4e4e4;
	border-top: none;
	padding: 12px 25px;
	border-radius: 0 0 3px 3px;
}

.plan-features ul li {
	padding: 12px 0;
	text-align: center;
	border-bottom: 1px solid #f0f0f0;
}

.plan-features a.button {
	position: relative;
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-top: 22px;
	margin-bottom: 10px;
	text-align: center;
	background-color: #b4b4b4;
	color: #fff;
}

.plan-features a.button:hover { background-color: #a7a7a7 }

/* Premium Plan Style */
.premium .plan-price,
.premium .plan-features a.button:hover { background-color: #68a914; }
.premium.plan h3,
.premium .plan-features a.button { background-color: #a0a0a0; }

.featured .plan-price,
.featured .plan-features a.button:hover { background-color: #5c5c5c; }
.featured.plan h3,
.featured .plan-features a.button { background-color: #666; }



/* =================================================================== */
/* Footer
====================================================================== */
#footer {
	background-color: #f8f8f8;
	padding: 35px 0 40px 0;
}

#footer h3 { margin-bottom: 10px; }


/* Dark Style
====================================*/
#footer.dark {
	background: #4c4c4c;
	color: #c8c8c8;
}

#footer.dark h3,
#footer.dark .get-in-touch li p strong,
.dark #twitter li a {
	color: #fff;
}

#footer-bottom.dark {
	background: #404040;
	color: #a8a8a8;
}

#footer-bottom.dark a {
	color: #e0e0e0;
}

#footer-bottom.dark a i {
	color: #707070;
}

#footer.dark .get-in-touch p,
#footer.dark ul.get-in-touch li,
#footer.dark .get-in-touch a { color: #c8c8c8 !important; }

#footer.dark .get-in-touch li i,
.dark #twitter li:before {
	color: #808080;
}

.dark #twitter b a,
.dark #twitter b a:hover {
	color: #909090;
}

.dark input.newsletter {
	background-color: #606060;
	border-color: #707070;
	color: #c8c8c8;
	box-shadow: none;
}


/* Footer Bottom
====================================*/
#footer-bottom {
	background-color: #a0a0a0;
	padding: 20px 0 17px 0;
	color: #fff;
}

#footer-bottom a {
	color: #fff;
	font-weight: 600;
}


/* Twitter Widget
====================================*/
#twitter li {
	margin: 0 0 15px 0;
	padding: 0 0 0 28px;
	float: left;
	line-height: 24px;
}

#twitter li:before {
	content:"\54";
	font-family: "fontello";
	font-size: 13px;
	float: left;
	margin: 0 0 0 -28px;
	color: #a6a6a6;
}

#twitter b a,
#twitter b a:hover {
	margin-top: 5px;
	display: block;
	color: #999;
	font-weight: normal;
}

.twitter-follow-button { margin-left: 28px; }


/* Get In Touch
====================================*/
.get-in-touch { margin: 15px 0 0 0; }

.get-in-touch li p strong {
	color: #888;
	float: left;
	font-weight: 600;
	margin: 0 5px 5px 0;
}

.get-in-touch .icon-map-marker {font-size: 16px;}
.get-in-touch li { margin: 0 0 15px 0; }

.get-in-touch p,
.get-in-touch li,
.get-in-touch a { color: #888 !important; }

.get-in-touch p {
	margin: -2px 0 0 25px;
	line-height: 20px;
}

.get-in-touch li i {
	float: left;
	margin: 2px 0 0 0;
	color: #a6a6a6;
}


/* Search / Newsletter Inputs
====================================*/
.newsletter-btn,
.search-btn {
	background-color: #a0a0a0;
	border-radius: 0 3px 3px 0;
	box-shadow: inset 0px -1px 0px 0px rgba(0,0,0, 0.1);
	border: 0;
	font-size: 14px;
	color: #fff;
	cursor: pointer;
	float: right;
	z-index: 5;
	position: relative;
	padding: 9px 16px;
	line-height: 18px;
	margin: 0;
	height: 38px;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.newsletter-btn:hover,
.search-btn:hover { background-color: #a0a0a0; }

.search-btn i {
	float: left;
	margin: -2px 0 0 0;
	padding: 0 3px;
}

.search-btn {
	padding: 9px 10px;
	width: 38px;
}

input.newsletter,
input.search {
	float: left;
	color: #aaa;
	box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
	border-radius: 3px 0 0 3px;
	z-index: 1;
	position: relative;
	font-size: 14px;
	width: 85%;
	margin-top: -38px;
	padding: 9px 12px;
	line-height: 18px;
	height: 18px;
}

/* =================================================================== */
/* Media Queries
====================================================================== */

/* Standard 1180 (devices and browsers) */
@media only screen and (min-width: 1200px){
	.caption.text { margin-left: 0 !important; }
	.caption h2,
	.caption h3,
	.caption p {margin:0;}
}

/* Smaller than standard 1180 (devices and browsers) */
@media only screen and (min-width: 959px) and (max-width: 1199px) {
	.caption.text { margin-left: 50px !important; }
	.caption h2,
	.caption h3,
	.caption p { margin:0; }
	
	.menu ul > li > a { padding-left: 15px; padding-right: 15px; }

	ol li ol.childlist .comment-des {
		float:right;
		width:78%;
	}

	.comment-des {
		float:right;
		width: 81%;
	}

	.add-comment textarea,
	#contact textarea { min-width: 97%; }
	
	.flickr-widget-blog a {
		width: 57px;
		height: 57px;
	}
	
	#not-found { margin: 50px 0 75px 0; }

	#not-found h2 {
		font-size: 210px;
		line-height: 210px;
	}

	#not-found p {
		font-size: 28px;
		line-height: 36px;
	}

	#not-found i {
		font-size: 200px;
		top: -5px;
	}

}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.caption.text { margin-left: 30px !important; }
	.caption h2 { margin-top: -8px;}
	.caption p { margin-top: 8px;}
	.img-1 {margin-left: 0 !important;}

	.menu ul > li > a { padding-left: 14px; padding-right: 14px; }

	#search-form {
		position: absolute;
		right:0;
	}

	.search-container { margin-left: 50px !important; }
	.the-icons { font-size: 13px; }

	ol li ol.childlist .comment-des {
		float:right;
		width:71%;
	}
	.comment-des {
		float:right;
		width: 75%;
	}

	.add-comment textarea,
	#contact textarea { min-width: 96%; }
	
	.flickr-widget-blog a {
		width: 69px;
		height: 69px;
	}
	
	#not-found {margin: 35px 0 60px 0;}
	
	#not-found h2 {
		font-size: 160px;
		line-height: 160px;
	}

	#not-found i {
		font-size: 155px;
		top: -4px;
	}

	#not-found p {
		font-size: 22px;
		line-height: 32px;
	}

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

	.caption.text {margin-left: 30px !important;}
	.caption h2 {font-size: 32px; margin-top: 10px;}
	.caption h3 {font-size: 20px; margin-top: 15px;}
	.caption p {display: none;}
	.img-1 {margin-left: 0 !important;}
	
	.fullwidthbanner-container { top: 0; }

	.showbiz.our-clients ul li img { margin: 0 auto; }

	#breadcrumbs ul {
		float: left;
		margin-top: 0;
		padding: 0 0 24px 0;
	}
	
	#titlebar {
		top: 0;
		margin-bottom: 40px;
	}
	
	#titlebar h2 {
		margin-top: 3px;
	}

	#googlemaps { padding-bottom: 40%; }
	.plan-features { margin-bottom: 25px; }
	.medium .post-img { margin-bottom: 20px; }
	
	#filters { margin: 10px 0 30px 0; }
	#filters a { margin: 0 5px 5px 0; }
	.line.filters { display: none; }

	.line.showing {
		display: inline-block;
		margin: -11px 0 0 0;
		z-index: 5;
	}
	
	.social-icons-footer {
		margin-top: 5px;
		float: left;
	}
	
	#footer { padding-top: 10px; }
	#footer h3 { margin-top: 30px; }
	
	.featured-boxes { margin: 40px 0 5px 0; }
	.featured-box { margin: 0 0 25px 0; }
	
	a.button.launch { margin: 0 0 30px 0; }
	#related-work figure {margin-bottom: 20px;}
	
	.search-form {
	 	margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid #f0f0f0;
	}
	
	#contact input[type="submit"] {
		margin: 0 0 30px 0;
	}
	
	#not-found {margin: 15px 0 25px 0;}
	
	#not-found h2 {
		font-size: 110px;
		line-height: 120px;
	}

	#not-found i {
		font-size: 105px;
		top: -2px;
	}

	#not-found p {
		font-size: 22px;
		line-height: 32px;
	}

}


/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {

	ol li ol.childlist .comment-des {
		float:right;
		width:63%;
	}

	.comment-des {
		float:right;
		width: 68%;
	}

	.add-comment textarea,
	#contact textarea { min-width: 95%; }
	
	.flickr-widget-blog a {
		width: 87px;
		height: 87px;
	}


}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {

	.caption h2 {font-size: 32px; margin-top: 0;}
	.caption h3 {font-size: 18px; margin-top: 10px;}
	.caption p {display: none}

	ol li ol.childlist .comment-des {
		float:right;
		width:49%;
	}

	.comment-des {
		float:right;
		width: 56%;
	}

	.add-comment textarea,
	#contact textarea { width: 90%; }
	
	.flickr-widget-blog a {
		width: 82px;
		height: 82px;
	}
	
	.dropcap {font-size: 52px;}

}